import * as React from 'react';
import { useTranslation } from "react-i18next";
import Typography from '@mui/material/Typography';


export default function Home() {
  const { t } = useTranslation();
  if (t("LANG") == "ru_RU")
    return (
      <Typography color="text.primary" size="big">

        <Typography variant="h6" color="text.primary" size="big">Активация датчиков TPMS для автомобилей Nissan и Infiniti</Typography>

        <Typography>
          Основные возможности:<br />
          - Для автомобилей Nissan Patrol(код кузова Y62), Infiniti QX56/QX80 (код кузова Z62), Infiniti Q70(Y51), Infiniti EX25, Infiniti EX35, Infiniti FX35(S51), Infiniti FX37(S51), Nissan Quest(E52), Nissan Elgrand(E52) активация датчиков TPMS (ID REGIST) аналогично дилерскому ПО Consult 3 plus<br />
          - Для автомобилей Infiniti QX56/QX80 (код кузова Z62) тест задней пневмоподвески: через модуль E-SUS включать выключать компрессор, открывать\закрывать клапан давления, включать\выключать индикатор CK SUSP на панели приборов, монитор параметров подвески:
          "VHCL HT INTLZ STA", "VHCL HT INIT SLCT", "VHCL HEIGHT SEN", "VHCL HT CNVSN VL", "HGT FIX TIME", "VHCL HGT INIT VAL", "COMPRESSOR",  "EXH SOLENOID"<br />
          - Чтение идентификационного номера электронных блоков<br />
          <br />
          Поддерживаемые адаптеры:<br />
          - OBD2 Bluetooth Low Energy<br />
          - OBD2 WiFi<br />
        </Typography>
      </Typography >
    );
  else
    return (
      <Typography color="text.primary" size="big">
        <Typography variant="h6" color="text.primary" size="big">Activation of TPMS sensors for Nissan and Infiniti vehicles</Typography>
        <Typography>
          Main features:<br />
          - For Nissan Patrol (body code Y62), Infiniti QX56/QX80 (body code Z62), Infiniti Q70(Y51), Infiniti EX25, Infiniti EX35, Infiniti FX35(S51), Infiniti FX37(S51), Nissan Quest(E52), Nissan Elgrand(E52) vehicles, TPMS sensor activation (ID REGIST) similar to the Consult 3 plus dealer software<br />
          - For Infiniti QX56/QX80 (body code Z62) vehicles, rear air suspension test: via the E-SUS module, turn on/off the compressor, open/close the pressure valve, turn on/off the CK SUSP indicator on the instrument panel, suspension parameter monitor:
          "VHCL HT INTLZ STA", "VHCL HT INIT SLCT", "VHCL HEIGHT SEN", "VHCL HT CNVSN VL", "HGT FIX TIME", "VHCL HGT INIT VAL", "COMPRESSOR", "EXH SOLENOID"<br />
          - Reading identification number of electronic units<br />
          <br />
          Supported adapters:<br />
          - OBD2 Bluetooth Low Energy<br />
          - OBD2 WiFi<br />
        </Typography >
      </Typography >
    );
}
